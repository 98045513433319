#App{
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
nav{
    width:20%;
    margin-right:2rem;
}
.nav-ul{
    display:flex;
    padding:0;
    justify-content: space-between;
}

.nav-ul li{
float:left;
list-style-type: none;


}
.source-span{
    position: absolute;
    color:#333;
    right:10px;
    bottom:10px;
    font-size: 0.75rem;
}
ul.nav-ul li a{
    text-decoration: none;
    font-size: 1.25rem;
    letter-spacing: 3px;
    color:#fefefe;
}
ul.nav-ul li a:hover{
    color:#b0c27e;

}

@media (max-width:380px){
    .MuiGrid-item{
        padding-left:0;
    }
}