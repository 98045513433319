#root{
}
body {
  margin: 0;
  padding:0;
  width:100%;
  min-height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
section { 
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:100vh;
}
section div{
  max-width: 1000px;
  
}